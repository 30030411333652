import { action } from "typesafe-actions";
import { IOrderDetails } from "./OrderDetails.typrs";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { IStoreState } from "../initialStoreState";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { api } from "../../api/api";

export const FETCH_ORDER_DETAILS_LIST_PROGRESS = "FETCH_ORDER_DETAILS_LIST_PROGRESS";
export const FETCH_ORDER_DETAILS_LIST_SUCCESS = "FETCH_ORDER_DETAILS_LIST_SUCCESS";
export const FETCH_ORDER_DETAILS_LIST_FAILED = "FETCH_ORDER_DETAILS_LIST_FAILED";

export const fetchOrderDetailsListProgress = () =>
    action(FETCH_ORDER_DETAILS_LIST_PROGRESS);
  
  export const fetchOrderDetailsListSuccess = (
    list: IOrderDetails[],
    totalRecords: number
  ) => action(FETCH_ORDER_DETAILS_LIST_SUCCESS, { list, totalRecords });
  
  export const fetchOrderDetailsListFailed = () => action(FETCH_ORDER_DETAILS_LIST_FAILED);
  
  export const fetchOrderDetailsListAsync =
    (
      pageNumber: number,
      rowsInPerPage: number,
      status: string,
      date: IDate,
      contactNo?: string,
      searchValue?: string,
      searchType?: string[]
    ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchOrderDetailsListProgress());
        let url = "";
        if (status !== "-1") {
          url = "&status=" + status;
        }
        if(contactNo){
            url+= `&contact_no=${contactNo}`
        }
        let finalUrl =
          `get-order-all?pageNo=${pageNumber}&itemsPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}` + url;
  
        const res = await api.get(finalUrl);
        const data: IOrderDetails[] = res.data.result;
        const totalRecords = res.data.totalRecords;
  
        dispatch(fetchOrderDetailsListSuccess(data, totalRecords));
      } catch (error) {
        dispatch(fetchOrderDetailsListFailed());
      }
    };
  