import { ICallReportState, ICallReportValueState } from "./CallReport";
import { defaultCallReportListState, defaultCallReportValueState } from "./CallReport/defaultState";
import { ICommon, initialCommonState } from "./common/types";
import { IComplaintsState, initialComplaintsState } from "./complaints/types";
import { ICustomizerState } from "./customizer/customizerReducer";
import { IDashbaord, initialDashboard } from "./dashboard/types";
import { IGoogleNearBy, IGoogleNearByState, IMechanicState, defaultGoogleNearByState, defaultMechanicState } from "./mechanics";
import { IMessagesState } from "./messages/messages.types";
import { initialMessagesState } from "./messages/messagesState";
import {
  initialOnlineDashboard,
  IOnlineDashbaord,
} from "./onlineDashboard/types";
import { IOrderDetailsState } from "./OrderDetails";
import { defaultOrderDetailsState } from "./OrderDetails/defaultState";
import { IProductState } from "./Product";
import { defaultProductState } from "./Product/defaultState";
import { initialPromoState, IPromoState } from "./PromoCode/types";
import {
  initialRegisteredUsersState,
  IRegisteredUsers,
} from "./registeredUsers/types";
import { initialSowDashboard, ISowDashbaord } from "./sowDashboard/types";
import { initialServicesState, IServiceState } from "./sowService/types";

export interface IDropdownListType {
  key: string;
  text: string;
  value: string;
}

export interface IStoreState {
  customizerReducer: ICustomizerState;
  common: ICommon;
  registeredUsers: IRegisteredUsers;
  dashboard: IDashbaord;
  sowDashboard: ISowDashbaord;
  complaints: IComplaintsState;
  promo: IPromoState;
  services: IServiceState;
  onlineDsa: IOnlineDashbaord;
  product: IProductState;
  mechanics: IMechanicState;
  message: IMessagesState;
googleNearBy:IGoogleNearByState;

callReport:ICallReportState;
callReportValue:ICallReportValueState;
orderDetails:IOrderDetailsState
}

export const initialStoreState: IStoreState = {
  customizerReducer: {
    activeDir: "ltr",
    activeNavbarBg: "#0b70fb", // This can be any color,
    activeSidebarBg: "#ffffff", // This can be any color
    activeMode: "light", // This can be light or dark
    activeTheme: "LIGHT_BLUE_THEME", // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME
    SidebarWidth: 240,
  },
  common: initialCommonState,
  mechanics: defaultMechanicState,
  message: initialMessagesState,
googleNearBy:defaultGoogleNearByState,
  registeredUsers: initialRegisteredUsersState,
  dashboard: initialDashboard,
  complaints: initialComplaintsState,
  promo: initialPromoState,
  services: initialServicesState,
  sowDashboard: initialSowDashboard,
  onlineDsa: initialOnlineDashboard,
  product: defaultProductState,
  callReport:defaultCallReportListState,
  callReportValue:defaultCallReportValueState,
  orderDetails:defaultOrderDetailsState
};
