import produce from "immer";
import { ComplaintActions } from ".";
import { LoadState } from "../../constants/enums";
import {
  ASSIGN_MECHANIC,
  FETCH_COMPLAINTS_FAILURE,
  FETCH_COMPLAINTS_PROGRESS,
  FETCH_COMPLAINTS_SUCCESS,
} from "../constants";
import { IStoreState } from "../initialStoreState";
import { initialComplaintsState } from "./types";

export const complaintsReducer = (
  state: IStoreState["complaints"] = initialComplaintsState,
  action: ComplaintActions
) => {
  switch (action.type) {
    case FETCH_COMPLAINTS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loadState = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_COMPLAINTS_SUCCESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.complaints = action.payload.complaints;
        draftState.list.loadState = LoadState.Loaded;
        draftState.list.totalRecords = action.payload.totalRecords;
        draftState.list.totalAmount = action.payload.totalAmount;
      });
      return newState;
    }
    case FETCH_COMPLAINTS_FAILURE: {
      const newState = produce(state, (draftState) => {
        draftState.list.loadState = LoadState.Failed;
      });
      return newState;
    }
    case ASSIGN_MECHANIC: {
      const { complaintNumber, mechanicId } = action.payload;
      const newState = produce(state, (draftState) => {
        const complaint = draftState.list.complaints.find(
          (x) => x.complaint_no === complaintNumber
        );
        if (complaint) {
          complaint.assigned_mechanic_id = mechanicId;
        }
      });
      return newState;
    }
    default: {
      return state;
    }
  }
};
