import { LoadState } from "../../constants/enums";
import { IOrderDetails, IOrderDetailsState } from "./OrderDetails.typrs";

export const defaultOrderDetails:IOrderDetails={
    order_id: 0,
    order_code: "",
    contact_no: "",
    username: "",
    email_id: "",
    city: "",
    state: "",
    address: "",
    pincode: "",
    ship_city: "",
    ship_state: "",
    ship_address: "",
    ship_pincode: "",
    ship_landmark: "",
    vehicle_no: "",
    vehicle_make: "",
    vehicle_model: "",
    order_type: "",
    order_quantity: 0,
    order_total_amount: "0.00",
    promo_code: "",
    payment_status: "",
    payment_mode: "",
    products_ordered_quantity: 0,
    products_orders: [],
    paid_amount: 0,
    gst_amount: 0,
    taxable_amount: 0,
    discount: "0.00",
    status: "",
    comment: "",
    iteration: 0,
    create_dt: "",
    insert_dt: "",
}

export const defaultOrderDetailsState:IOrderDetailsState={
    list:{
        data:[],
        loading:LoadState.NotLoaded,
        error:null,
        totalRecords:0    

    },
    orderDetails:{
        data:defaultOrderDetails,
        loading:LoadState.NotLoaded,
        error:null
    }
}