import { IComplaint } from "../../constants/commonTypes";
import { LoadState } from "../../constants/enums";

export interface IComplaintsState {
  list: {
    complaints: IComplaint[];
    totalRecords: number;
    totalAmount: string;
    loadState: LoadState;
    error: string | null;
  };
}

export const initialComplaintsState: IComplaintsState = {
  list: {
    complaints: [],
    totalRecords: 0,
    totalAmount: "",
    loadState: LoadState.NotLoaded,
    error: null,
  },
};
