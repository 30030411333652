import produce from "immer";
import { FETCH_ORDER_DETAILS_LIST_FAILED, FETCH_ORDER_DETAILS_LIST_PROGRESS, FETCH_ORDER_DETAILS_LIST_SUCCESS, OrderDetailsActions } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { defaultOrderDetailsState } from "./defaultState";

export const orderDetailsReducer = (
    state: IStoreState["orderDetails"] = defaultOrderDetailsState,
    action: OrderDetailsActions
  ) => {
    switch (action.type) {
      case FETCH_ORDER_DETAILS_LIST_PROGRESS: {
        const newState = produce(state, (draftState: any) => {
          draftState.list.loading = LoadState.InProgress;
          draftState.list.data = [];
          draftState.list.totalRecords = 0;
        });
        return newState;
      }
      case FETCH_ORDER_DETAILS_LIST_SUCCESS: {
        const { list, totalRecords } = action.payload;
        const newState = produce(state, (draftState: any) => {
          draftState.list.loading = LoadState.Loaded;
          draftState.list.data = list;
          draftState.list.totalRecords = totalRecords;
        });
        return newState;
      }
      case FETCH_ORDER_DETAILS_LIST_FAILED: {
        const newState = produce(state, (draftState: any) => {
          draftState.list.loading = LoadState.InProgress;
          draftState.list.data = [];
          draftState.list.totalRecords = 0;
        });
        return newState;
      }
    
      default: {
        return state;
      }
    }
  };
  